@import "_library/_mixin";
@import "_library/_colors";
@import "_library/_fonts";

.wysiwig{
  p{
    margin: 1em 0;
  }
  a {
    color:  #2e78ce ;
    text-decoration: underline;
  }
  blockquote{
    margin: 0;
    padding: 10px;
    background-color: #ddd;
    position: relative;
    &:before{
      position: absolute;
      left: 5px;
      top: 5px;
      font-family: fontAwesome;
      content: '\f10d';
      color: #888888;
      font-size: 1em;
    }
    &:after{
      position: absolute;
      right: 5px;
      bottom: 5px;
      font-family: fontAwesome;
      content: '\f10e';
      color: #888888;
      font-size: 1em;
    }
  }
  h1{
    font-size: 2em;
  }
  h2{
    font-size: 1.8em;
  }
  h3{
    font-size: 1.6em;
  }
  h4{
    font-size: 1.4em;
  }
  h5{
    font-size: 1.2em;
  }
  h6{
    font-size: 1em;
  }
  table, td, th {
    border: 1px #ddd solid;
  }
  th {
    background-color: #ddd;
  }
}
.wp-editor{
  font-family: $fontFamiry;
  padding: 20px !important;html,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  // ul,
  // ol,
  dl,
  li,
  dt,
  dd,
  p,
  div,
  span,
  img,
  a,
  table,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-feature-settings:"palt";
  }
  // ol,
  // ul {
  //   list-style: none;
  //   list-style-type: none;
  // }
  a {
    text-decoration: none;
    color: inherit;

    &:hover{
      cursor: pointer;
    }
  }
  &#tinymce{
    @extend .wysiwig;
  }
}
